// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommunityEntity-module__wrapper___U_pGw{display:grid;grid-gap:10px;gap:10px}.CommunityEntity-module__containerItem___lZpeo{display:grid;grid-template-columns:repeat(auto-fit, minmax(100px, 100px));grid-gap:10px;gap:10px;width:100%;padding:0 20px}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/Follows/ui/CommunityEntity.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,aAAA,CAAA,QAAA,CAGF,+CACE,YAAA,CACA,4DAAA,CAEA,aAAA,CAAA,QAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  display: grid;\n  gap: 10px;\n}\n\n.containerItem {\n  display: grid;\n  grid-template-columns: repeat(auto-fit,\n      minmax(100px, 100px));\n  gap: 10px;\n  width: 100%;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CommunityEntity-module__wrapper___U_pGw",
	"containerItem": "CommunityEntity-module__containerItem___lZpeo"
};
export default ___CSS_LOADER_EXPORT___;
