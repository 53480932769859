// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Taverna-module__infiniteScroll___HuBKg{margin-top:12px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.Taverna-module__infiniteScroll___HuBKg>*{width:100%}", "",{"version":3,"sources":["webpack://./src/containers/taverna/Taverna.module.scss"],"names":[],"mappings":"AAAA,wCACE,eAAA,CAEA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,0CACE,UAAA","sourcesContent":[".infiniteScroll {\n  margin-top: 12px;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  >* {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infiniteScroll": "Taverna-module__infiniteScroll___HuBKg"
};
export default ___CSS_LOADER_EXPORT___;
