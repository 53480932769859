// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeHistoryItem-module__wrapper___p4VXE{display:grid;grid-template-columns:2fr 1fr;justify-items:end;-webkit-box-align:center;-ms-flex-align:center;align-items:center;grid-gap:7px;gap:7px;font-size:14px;line-height:17px;max-width:200px}.TimeHistoryItem-module__wrapper___p4VXE>span{font-size:10px;line-height:10px}", "",{"version":3,"sources":["webpack://./src/features/TimeHistory/ui/TimeHistoryItem/TimeHistoryItem.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CAEA,6BAAA,CACA,iBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,YAAA,CAAA,OAAA,CAEA,cAAA,CACA,gBAAA,CACA,eAAA,CAEA,8CACE,cAAA,CACA,gBAAA","sourcesContent":[".wrapper {\n  display: grid;\n  // grid-template-columns: 110px 1fr;\n  grid-template-columns: 2fr 1fr;\n  justify-items: end;\n  align-items: center;\n  gap: 7px;\n  // padding: 0 5px;\n  font-size: 14px;\n  line-height: 17px;\n  max-width: 200px;\n\n  & > span {\n    font-size: 10px;\n    line-height: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TimeHistoryItem-module__wrapper___p4VXE"
};
export default ___CSS_LOADER_EXPORT___;
