// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__wrapper___oNqZl{width:62%;margin:0 auto;display:grid;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;padding-bottom:102px}@media(max-width: 650px){.styles-module__wrapper___oNqZl{width:82%}}@media(max-width: 540px){.styles-module__wrapper___oNqZl{width:100% !important}}.styles-module__wrapper___oNqZl>main{min-height:100%;display:grid}.styles-module__wrapper___oNqZl>main>header{-ms-flex-item-align:start;align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/components/MainContainer/styles.module.scss","webpack://./src/layouts/variables.module.scss"],"names":[],"mappings":"AAEA,gCACE,SAAA,CACA,aAAA,CACA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAGA,oBCIgB,CDFhB,yBATF,gCAUI,SAAA,CAAA,CAGF,yBAbF,gCAcI,qBAAA,CAAA,CAGF,qCAEE,eAAA,CAGA,YAAA,CAEA,4CAEE,yBAAA,CAAA,qBAAA","sourcesContent":["@import '../../layouts/variables.module.scss';\n\n.wrapper {\n  width: 62%;\n  margin: 0 auto;\n  display: grid;\n  align-items: flex-start;\n\n  // padding for action bar, if scroll bottom\n  padding-bottom: $actionBarHeight;\n\n  @media (max-width: 650px) {\n    width: 82%;\n  }\n\n  @media (max-width: 540px) {\n    width: 100% !important;\n  }\n\n  >main {\n    // same with menu\n    min-height: 100%;\n\n    // stretch children by height\n    display: grid;\n\n    >header {\n      // because of grid\n      align-self: flex-start;\n    }\n  }\n}","$headerHeight: 105px;\n\n$adviserHeight: 60px;\n$adviserOffset: 15px;\n\n$robotHeaderHeight: 95px;\n$robotNotOwnerMenuHeight: 45px;\n\n$robotHeaderHeightNotOwner: $robotHeaderHeight + $robotNotOwnerMenuHeight;\n\n\n$reservedTopHeight: $headerHeight + $adviserHeight - $adviserOffset;\n\n$actionBarHeight: 102px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles-module__wrapper___oNqZl"
};
export default ___CSS_LOADER_EXPORT___;
