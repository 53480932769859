import React, { useContext, useEffect, useMemo, useState } from 'react';
import useGetMarketData from 'src/hooks/useGetMarketData';
import useConvertMarketData from 'src/hooks/warp/useConvertMarketData';
import { ObjKeyValue } from 'src/types/data';
import { useWebsockets } from 'src/websockets/context';

type OptionObj<T> = T | object;

type ObjData = OptionObj<ObjKeyValue>;

type DataProviderContextType = {
  marketData: ObjData;
  dataTotalSupply: ObjData;
  block: number | null;
};

const valueContext = {
  marketData: {},
  dataTotalSupply: {},
  block: null,
};

const DataProviderContext =
  React.createContext<DataProviderContextType>(valueContext);

export function useAppData() {
  return useContext(DataProviderContext);
}

function DataProvider({ children }: { children: React.ReactNode }) {
  const { marketData, dataTotal } = useGetMarketData();
  const convertMarketData = useConvertMarketData(marketData);
  const { ethos } = useWebsockets();
  const [blockHeight, setBlockHeight] = useState<number | null>(null);

  const resultMarketData = Object.keys(convertMarketData).length
    ? convertMarketData
    : marketData;

  useEffect(() => {
    if (!ethos?.connected) {
      return;
    }

    const param = "tm.event='NewBlockHeader'";

    if (ethos.subscriptions.includes(param)) {
      return;
    }

    ethos.sendMessage({
      method: 'subscribe',
      params: [param],
      id: '1',
      jsonrpc: '2.0',
    });
  }, [ethos, ethos?.connected]);

  useEffect(() => {
    if (!ethos?.message?.result) {
      return;
    }

    const message = ethos?.message;

    if (
      Object.keys(message.result).length > 0 &&
      message.result.data.type === 'tendermint/event/NewBlockHeader'
    ) {
      const { height } = message.result.data.value.header;
      setBlockHeight(height);
    }
  }, [ethos?.message]);

  const valueMemo = useMemo(
    () => ({
      marketData: resultMarketData,
      dataTotalSupply: dataTotal,
      block: blockHeight,
    }),
    [resultMarketData, dataTotal, blockHeight]
  );

  return (
    <DataProviderContext.Provider value={valueMemo}>
      {children}
    </DataProviderContext.Provider>
  );
}

export default DataProvider;
