// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RouteItem-module__wrapper___PP3LA{display:grid;grid-gap:8px;gap:8px;grid-template-columns:70px 15px;color:inherit;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.RouteItem-module__wrapper___PP3LA span{overflow-x:hidden;text-overflow:ellipsis;white-space:nowrap}.RouteItem-module__wrapper___PP3LA img{width:15px;height:15px}", "",{"version":3,"sources":["webpack://./src/features/TimeHistory/ui/RouteItem/RouteItem.module.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,YAAA,CAAA,OAAA,CACA,+BAAA,CACA,aAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,wCACE,iBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,uCACE,UAhBO,CAiBP,WAjBO","sourcesContent":["$size-img: 15px;\n\n.wrapper {\n  display: grid;\n  gap: 8px;\n  grid-template-columns: 70px $size-img;\n  color: unset;\n  align-items: center;\n\n  span {\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  img {\n    width: $size-img;\n    height: $size-img;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "RouteItem-module__wrapper___PP3LA"
};
export default ___CSS_LOADER_EXPORT___;
