// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".soulCompanion-module__itemLinks___na2os{display:-webkit-box;display:-ms-flexbox;display:flex;list-style-type:none;font-size:14px}.soulCompanion-module__soulCompanion___iAkfq{margin:-10px 0}.soulCompanion-module__itemText___dOzLh{font-size:14px;display:block}.soulCompanion-module__itemLink____9mIx{margin:0 5px;display:block;overflow:hidden;text-overflow:ellipsis;max-width:158px;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}", "",{"version":3,"sources":["webpack://./src/containers/ipfs/components/SoulCompanion/soulCompanion.module.scss"],"names":[],"mappings":"AAAA,yCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEA,oBAAA,CACA,cAAA,CAGF,6CACE,cAAA,CAGF,wCACE,cAAA,CACA,aAAA,CAGF,wCACE,YAAA,CACA,aAAA,CAEA,eAAA,CACA,sBAAA,CACA,eAAA,CACA,mBAAA,CACA,oBAAA,CACA,2BAAA","sourcesContent":[".itemLinks {\n  display: flex;\n\n  list-style-type: none;\n  font-size: 14px;\n}\n\n.soulCompanion {\n  margin: -10px 0;\n}\n\n.itemText {\n  font-size: 14px;\n  display: block;\n}\n\n.itemLink {\n  margin: 0 5px;\n  display: block;\n  // white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 158px;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemLinks": "soulCompanion-module__itemLinks___na2os",
	"soulCompanion": "soulCompanion-module__soulCompanion___iAkfq",
	"itemText": "soulCompanion-module__itemText___dOzLh",
	"itemLink": "soulCompanion-module__itemLink____9mIx"
};
export default ___CSS_LOADER_EXPORT___;
