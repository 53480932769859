// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stats-module__wrapper___uvM1r{font-size:20px;display:none;min-height:25px}.Stats-module__wrapper___uvM1r strong{color:var(--green-light)}.Stats-module__wrapper___uvM1r .Stats-module__change___oqXyJ{color:rgba(221,255,255,.38);display:inline}.Stats-module__wrapper___uvM1r .Stats-module__change___oqXyJ strong{color:#fff}", "",{"version":3,"sources":["webpack://./src/pages/oracle/landing/Stats/Stats.module.scss"],"names":[],"mappings":"AAAA,+BACE,cAAA,CACF,YAAA,CAEE,eAAA,CAEA,sCACE,wBAAA,CAGF,6DACE,2BAAA,CACA,cAAA,CAEA,oEACE,UAAA","sourcesContent":[".wrapper {\n  font-size: 20px;\ndisplay: none;\n  // render to prevent jumps\n  min-height: 25px;\n\n  strong {\n    color: var(--green-light);\n  }\n\n  .change {\n    color: rgba(221, 255, 255, 0.38);\n    display: inline;\n\n    strong {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Stats-module__wrapper___uvM1r",
	"change": "Stats-module__change___oqXyJ"
};
export default ___CSS_LOADER_EXPORT___;
