// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__portal___uMqyJ{overflow:hidden;position:absolute;top:0;left:0;right:0;bottom:0}", "",{"version":3,"sources":["webpack://./src/containers/application/styles.scss"],"names":[],"mappings":"AAAA,wBAEE,eAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA","sourcesContent":[".portal {\n  // z-index: -1;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal": "styles__portal___uMqyJ"
};
export default ___CSS_LOADER_EXPORT___;
