// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BurgerIcon-module__networkBtnImgIconMenu___rp8hd{position:absolute;top:50%;left:50%;-webkit-transition:.2s;transition:.2s;-webkit-transform:translate(-50%, -50%) rotate(-90deg);transform:translate(-50%, -50%) rotate(-90deg)}.BurgerIcon-module__networkBtnImgIconMenuClose___AfoJD{-webkit-transform:translate(-50%, -50%) rotate(0deg);transform:translate(-50%, -50%) rotate(0deg)}.BurgerIcon-module__networkBtnImgIconMenu___rp8hd div{width:15px;height:2px;background-color:#000;margin:2px 0}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/CurrentApp/ui/BurgerIcon/BurgerIcon.module.scss"],"names":[],"mappings":"AAAA,kDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,sBAAA,CAAA,cAAA,CACA,sDAAA,CAAA,8CAAA,CAEA,uDACE,oDAAA,CAAA,4CAAA,CAGF,sDACE,UAAA,CACA,UAAA,CACA,qBAAA,CACA,YAAA","sourcesContent":[".networkBtnImgIconMenu {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transition: 0.2s;\n  transform: translate(-50%, -50%) rotate(-90deg);\n\n  &Close {\n    transform: translate(-50%, -50%) rotate(0deg);\n  }\n\n  div {\n    width: 15px;\n    height: 2px;\n    background-color: black;\n    margin: 2px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"networkBtnImgIconMenu": "BurgerIcon-module__networkBtnImgIconMenu___rp8hd",
	"networkBtnImgIconMenuClose": "BurgerIcon-module__networkBtnImgIconMenuClose___AfoJD"
};
export default ___CSS_LOADER_EXPORT___;
