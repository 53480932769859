// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RuneOutput-module__logArea___L_mcL{font-family:monospace !important}\n.RuneOutput-module__logArea___L_mcL{padding:10px;-webkit-box-shadow:none;box-shadow:none;border-radius:0;resize:vertical}", "",{"version":3,"sources":["webpack://./src/pages/robot/Soul/RuneEditor/RuneOutput/RuneOutput.module.scss"],"names":[],"mappings":"AAAA,oCAIE,gCACA;AALF,oCACE,YAAA,CACA,uBAAA,CAAA,eAAA,CACA,eAAA,CAEA,eAAA","sourcesContent":[".logArea {\n  padding: 10px;\n  box-shadow: none;\n  border-radius: 0;\n  font-family: monospace !important;\n  resize: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logArea": "RuneOutput-module__logArea___L_mcL"
};
export default ___CSS_LOADER_EXPORT___;
