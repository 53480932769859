export const localStorageKeys = {
  pocket: {
    POCKET: 'pocket',
    POCKET_ACCOUNT: 'pocketAccount',
  },
  MENU_SHOW: 'menuShow',
  settings: {
    adviserAudio: 'adviserAudio',
    adviserVoice: 'adviserVoice',
  },
};
