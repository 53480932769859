// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabsNotOwner-module__wrapper____98tY{display:-webkit-box;display:-ms-flexbox;display:flex;gap:5px;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.TabsNotOwner-module__wrapper____98tY .TabsNotOwner-module__icon___SIYA_{color:#fff;text-transform:none;text-transform:initial;font-size:20px}", "",{"version":3,"sources":["webpack://./src/pages/robot/Layout/RobotHeader/ui/TabsNotOwner/TabsNotOwner.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGA,yEACE,UAAA,CACA,mBAAA,CAAA,sBAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 5px;\n  align-items: center;\n\n\n  .icon {\n    color: #fff;\n    text-transform: initial;\n    font-size: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TabsNotOwner-module__wrapper____98tY",
	"icon": "TabsNotOwner-module__icon___SIYA_"
};
export default ___CSS_LOADER_EXPORT___;
