// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Karma-module__containerKarma___mBLy0{display:-webkit-box;display:-ms-flexbox;display:flex;gap:6px;color:#fff;font-size:16px;-webkit-transition:.4s;transition:.4s}", "",{"version":3,"sources":["webpack://./src/containers/application/Karma/Karma.module.scss"],"names":[],"mappings":"AAAA,sCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CACA,UAAA,CACA,cAAA,CACA,sBAAA,CAAA,cAAA","sourcesContent":[".containerKarma {\n  display: flex;\n  gap: 6px;\n  color: #fff;\n  font-size: 16px;\n  transition: 0.4s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerKarma": "Karma-module__containerKarma___mBLy0"
};
export default ___CSS_LOADER_EXPORT___;
