// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".time-module__wrapper___cQa_5{display:-webkit-box;display:-ms-flexbox;display:flex;color:var(--blue-light);gap:5px}.time-module__wrapper___cQa_5 .time-module__prefix___hyS9a{color:var(--grayscale-dark)}", "",{"version":3,"sources":["webpack://./src/components/time/time.module.scss"],"names":[],"mappings":"AAAA,8BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CACA,OAAA,CAEA,2DACE,2BAAA","sourcesContent":[".wrapper {\n  display: flex;\n  color: var(--blue-light);\n  gap: 5px;\n\n  .prefix {\n    color: var(--grayscale-dark);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "time-module__wrapper___cQa_5",
	"prefix": "time-module__prefix___hyS9a"
};
export default ___CSS_LOADER_EXPORT___;
