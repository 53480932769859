// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Brain-module__wrapper___eLptw{display:grid}.Brain-module__tabs___M8bJq{padding:10px 0px;width:max(300px, min(100%, 380px));margin:0 auto}", "",{"version":3,"sources":["webpack://./src/pages/robot/Brain/Brain.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CAIF,4BACE,gBAAA,CACA,kCAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  display: grid;\n}\n\n\n.tabs {\n  padding: 10px 0px;\n  width: clamp(300px, 100%, 380px);\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Brain-module__wrapper___eLptw",
	"tabs": "Brain-module__tabs___M8bJq"
};
export default ___CSS_LOADER_EXPORT___;
