// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppName-module__wrapper____zqY3{width:100%;overflow-x:hidden;text-overflow:ellipsis;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/CurrentApp/ui/AppName/AppName.module.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AppName-module__wrapper____zqY3"
};
export default ___CSS_LOADER_EXPORT___;
