// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Follows-module__wrapper___cG9Mn{display:grid;grid-gap:20px;gap:20px;position:relative;left:-2px}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/Follows/Follows.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,aAAA,CAAA,QAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".wrapper {\n  display: grid;\n  gap: 20px;\n  position: relative;\n  left: -2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Follows-module__wrapper___cG9Mn"
};
export default ___CSS_LOADER_EXPORT___;
