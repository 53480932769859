// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionBtn-module__questionBtn___gSVCv{color:var(--primary-color) !important}.QuestionBtn-module__questionBtn___gSVCv{border:1px solid var(--primary-color);text-shadow:0 0 5px var(--primary-color);border-radius:50%;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:16px;height:16px}.QuestionBtn-module__questionBtn___gSVCv:hover{opacity:.8}", "",{"version":3,"sources":["webpack://./src/components/Rank/QuestionBtn/QuestionBtn.module.scss"],"names":[],"mappings":"AAAA,yCAEE,qCAOA,CATF,yCACE,qCAAA,CAEA,wCAAA,CACA,iBAAA,CACA,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,UAAA,CACA,WAAA,CAEA,+CACE,UAAA","sourcesContent":[".questionBtn {\n  border: 1px solid var(--primary-color);\n  color: var(--primary-color) !important;\n  text-shadow: 0 0 5px var(--primary-color);\n  border-radius: 50%;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 16px;\n  height: 16px;\n\n  &:hover {\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionBtn": "QuestionBtn-module__questionBtn___gSVCv"
};
export default ___CSS_LOADER_EXPORT___;
