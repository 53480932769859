export const KEY_TYPE = {
  keplr: 'keplr',
  readOnly: 'read-only',
  secrets: 'secrets',
};

export const KEY_LIST_TYPE = {
  key: 'key',
  secret: 'secret',
};
