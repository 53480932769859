// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main-module__wrapper___I5yGQ footer{display:-webkit-box;display:-ms-flexbox;display:flex;position:fixed;z-index:2;width:100%;bottom:0px;background-color:#000}.Main-module__wrapper___I5yGQ .Main-module__Time___rgHF1{display:-webkit-box;display:-ms-flexbox;display:flex;position:fixed;right:1vw;height:40px;bottom:0px}@media(max-width: 540px){.Main-module__wrapper___I5yGQ .Main-module__Time___rgHF1{right:2vw}}", "",{"version":3,"sources":["webpack://./src/layouts/Main.module.scss"],"names":[],"mappings":"AACE,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,cAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA,CACA,qBAAA,CAYF,yDACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,cAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CAEA,yBAPF,yDAQI,SAAA,CAAA","sourcesContent":[".wrapper {\n  footer {\n    display: flex;\n    position: fixed;\n    z-index: 2;\n    width: 100%;\n    bottom: 0px;\n    background-color: #000;\n\n    // a {\n    //   position: fixed;\n    //   z-index: 2;\n    //   right: 5vw;\n    //   bottom: 35px;\n    //   display: flex;\n    //   align-items: center;\n    // }\n  }\n\n  .Time {\n    display: flex;\n    position: fixed;\n    right: 1vw;\n    height: 40px;\n    bottom: 0px;\n\n    @media (max-width: 540px) {\n      right: 2vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Main-module__wrapper___I5yGQ",
	"Time": "Main-module__Time___rgHF1"
};
export default ___CSS_LOADER_EXPORT___;
