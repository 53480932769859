// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Freestyle-module__wrapper___KhS4E{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.Freestyle-module__logArea___ZpG7v{font-family:monospace !important}.Freestyle-module__logArea___ZpG7v{padding:10px;-webkit-box-shadow:none;box-shadow:none;border-radius:0;resize:vertical}.Freestyle-module__header___Fe9Jn{padding-bottom:5px;margin:10px 0;width:100%;border-bottom:solid #36d6ae 1px}", "",{"version":3,"sources":["webpack://./src/pages/robot/Soul/RuneEditor/FreestyleIde/Freestyle.module.scss"],"names":[],"mappings":"AAEA,mCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGF,mCAIE,gCACA,CALF,mCACE,YAAA,CACA,uBAAA,CAAA,eAAA,CACA,eAAA,CAEA,eAAA,CAGF,kCACE,kBAAA,CACA,aAAA,CACA,UAAA,CACA,+BAAA","sourcesContent":["// @import '../../style/mixins.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.logArea {\n  padding: 10px;\n  box-shadow: none;\n  border-radius: 0;\n  font-family: monospace !important;\n  resize: vertical;\n}\n\n.header {\n  padding-bottom: 5px;\n  margin: 10px 0;\n  width: 100%;\n  border-bottom: solid rgb(54, 214, 174) 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Freestyle-module__wrapper___KhS4E",
	"logArea": "Freestyle-module__logArea___ZpG7v",
	"header": "Freestyle-module__header___Fe9Jn"
};
export default ___CSS_LOADER_EXPORT___;
