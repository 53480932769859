// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SoulIde-module__wrapper___PYaQ2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.SoulIde-module__separator___n8ggy{margin:10px 0;width:100%;padding-top:20px;border-top:#36d6ae solid 1px}", "",{"version":3,"sources":["webpack://./src/pages/robot/Soul/RuneEditor/SoulIde/SoulIde.module.scss"],"names":[],"mappings":"AAEA,iCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGF,mCACE,aAAA,CACA,UAAA,CACA,gBAAA,CACA,4BAAA","sourcesContent":["// @import '../../style/mixins.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.separator {\n  margin: 10px 0;\n  width: 100%;\n  padding-top: 20px;\n  border-top: rgb(54, 214, 174) solid 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SoulIde-module__wrapper___PYaQ2",
	"separator": "SoulIde-module__separator___n8ggy"
};
export default ___CSS_LOADER_EXPORT___;
