// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogItemContent-module__wrapper___cZfcE{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;max-width:82%;gap:10px;-ms-flex-item-align:end;align-self:flex-end}.LogItemContent-module__content___beAvY{color:#fff !important}.LogItemContent-module__content___beAvY{word-break:break-word;padding:13px 10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;font-size:14px;min-height:50px;min-width:100px;position:relative;gap:10px 0;cursor:pointer}.LogItemContent-module__content___beAvY:hover{opacity:.9}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/feeds/ui/LogItemContent.module.scss"],"names":[],"mappings":"AAAA,wCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CACA,aAAA,CAEA,QAAA,CAEA,uBAAA,CAAA,mBAAA,CAGF,wCAWE,qBAGA,CAdF,wCACE,qBAAA,CACA,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAEA,cAAA,CACA,eAAA,CACA,eAAA,CAGA,iBAAA,CACA,UAAA,CACA,cAAA,CAGE,8CACC,UAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  max-width: 82%;\n\n  gap: 10px;\n\n  align-self: flex-end;\n}\n\n.content {\n  word-break: break-word;\n  padding: 13px 10px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n\n  font-size: 14px;\n  min-height: 50px;\n  min-width: 100px;\n\n  color: white !important;\n  position: relative;\n  gap: 10px 0;\n  cursor: pointer;\n\n  \n    &:hover {\n     opacity: 0.9;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LogItemContent-module__wrapper___cZfcE",
	"content": "LogItemContent-module__content___beAvY"
};
export default ___CSS_LOADER_EXPORT___;
