// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SenseViewer-module__wrapper___Yu_Ko{width:100%}.SenseViewer-module__wrapper___Yu_Ko>div>div{display:-webkit-box;display:-ms-flexbox;display:flex;overflow-x:hidden;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.SenseViewer-module__wrapper___Yu_Ko>div>header{top:0}.SenseViewer-module__wrapper___Yu_Ko>div>header>div{left:-2px;margin:0}.SenseViewer-module__noData___IbJHn{margin:auto;color:#616161;text-align:center}", "",{"version":3,"sources":["webpack://./src/features/sense/ui/SenseViewer/SenseViewer.module.scss"],"names":[],"mappings":"AAAA,qCACE,UAAA,CAIE,6CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,iBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAMF,gDACE,KAAA,CAEA,oDACE,SAAA,CACA,QAAA,CAMR,oCACE,WAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n\n  // Display\n  > div {\n    > div {\n      display: flex;\n      overflow-x: hidden;\n      flex-direction: column;\n\n      // height: auto !important;\n    }\n\n    // Display title\n    > header {\n      top: 0;\n\n      > div {\n        left: -2px;\n        margin: 0;\n      }\n    }\n  }\n}\n\n.noData {\n  margin: auto;\n  color: #616161;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SenseViewer-module__wrapper___Yu_Ko",
	"noData": "SenseViewer-module__noData___IbJHn"
};
export default ___CSS_LOADER_EXPORT___;
