// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Commander-module__wrapper___Nyz_m{width:62%;-webkit-transform:translate(-50%, -80%);transform:translate(-50%, -80%);background:rgba(0,0,0,.79);margin-right:-50%;left:50%;position:absolute;top:50%;z-index:1;max-width:calc(100vw - 200px - 300px - 100px)}@media(max-width: 540px){.Commander-module__wrapper___Nyz_m{width:40%;max-width:none;max-width:initial}}.Commander-module__input___zcssz{text-align:center !important;font-size:20px !important}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/Commander/Commander.module.scss"],"names":[],"mappings":"AAAA,mCACE,SAAA,CACA,uCAAA,CAAA,+BAAA,CACA,0BAAA,CACA,iBAAA,CACA,QAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA,CAEA,6CAAA,CAEA,yBAZF,mCAaI,SAAA,CACA,cAAA,CAAA,iBAAA,CAAA,CAIJ,iCACE,4BAAA,CACA,yBAAA","sourcesContent":[".wrapper {\n  width: 62%;\n  transform: translate(-50%, -80%);\n  background: rgb(0 0 0 / 79%);\n  margin-right: -50%;\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  z-index: 1;\n  // width left and right sideBar , gap and paddings \n  max-width: calc(100vw - 200px - 300px - 100px);\n\n  @media (max-width: 540px) {\n    width: 40%;\n    max-width: unset;\n  }\n}\n\n.input {\n  text-align: center !important;\n  font-size: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Commander-module__wrapper___Nyz_m",
	"input": "Commander-module__input___zcssz"
};
export default ___CSS_LOADER_EXPORT___;
