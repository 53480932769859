// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreedView-modile__wrapper___boX10{display:grid;grid-gap:20px;gap:20px}", "",{"version":3,"sources":["webpack://./src/pages/robot/Brain/ui/TreedView.modile.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,aAAA,CAAA,QAAA","sourcesContent":[".wrapper {\n  display: grid;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TreedView-modile__wrapper___boX10"
};
export default ___CSS_LOADER_EXPORT___;
