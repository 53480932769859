// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardPassport-module__rows___FSFNH{padding-left:34px;padding-right:22px;padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/containers/sigma/components/CardPassport/CardPassport.module.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAA,CACA,kBAAA,CACA,mBAAA","sourcesContent":[".rows {\n  padding-left: 34px;\n  padding-right: 22px;\n  padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rows": "CardPassport-module__rows___FSFNH"
};
export default ___CSS_LOADER_EXPORT___;
