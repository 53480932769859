// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChainInfo-module__containerBandwidthBar___tF17Y{width:100%}.ChainInfo-module__btnContainerText___x9mIj{border:none;font-size:16px;background:rgba(0,0,0,0);color:#1fcbff;cursor:pointer}.ChainInfo-module__containerInfoSwitch___bQM4O{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:100%;width:75px;gap:10px;color:var(--blue-light)}", "",{"version":3,"sources":["webpack://./src/containers/application/Header/CurrentApp/ui/ChainInfo/ChainInfo.module.scss"],"names":[],"mappings":"AAAA,iDACE,UAAA,CAGF,4CACE,WAAA,CACA,cAAA,CACA,wBAAA,CACA,aAAA,CACA,cAAA,CAGF,+CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,QAAA,CACA,uBAAA","sourcesContent":[".containerBandwidthBar {\n  width: 100%;\n}\n\n.btnContainerText {\n  border: none;\n  font-size: 16px;\n  background: transparent;\n  color: rgb(31, 203, 255);\n  cursor: pointer;\n}\n\n.containerInfoSwitch {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 75px;\n  gap: 10px;\n  color: var(--blue-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBandwidthBar": "ChainInfo-module__containerBandwidthBar___tF17Y",
	"btnContainerText": "ChainInfo-module__btnContainerText___x9mIj",
	"containerInfoSwitch": "ChainInfo-module__containerInfoSwitch___bQM4O"
};
export default ___CSS_LOADER_EXPORT___;
