// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphHoverInfo-module__hoverInfo___UJD9f{position:relative;position:absolute;z-index:0;max-width:480px;border-radius:5px}.GraphHoverInfo-module__hoverInfo___UJD9f:before{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;z-index:-1;-webkit-backdrop-filter:blur(15px);backdrop-filter:blur(15px);-webkit-box-shadow:0px 0px 10px rgba(75,0,130,.09);box-shadow:0px 0px 10px rgba(75,0,130,.09);background-color:rgba(75,0,130,.09)}", "",{"version":3,"sources":["webpack://./src/features/cyberlinks/CyberlinksGraph/GraphHoverInfo/GraphHoverInfo.module.scss","webpack://./src/components/atoms/glass/mixins.scss"],"names":[],"mappings":"AAEA,0CCOE,iBAAA,CDJA,iBAAA,CACA,SAAA,CACA,eAAA,CACA,iBAAA,CCGA,iDACE,UAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CAfF,kCAAA,CAAA,0BAAA,CACA,kDAAA,CAAA,0CAAA,CACA,mCAAA","sourcesContent":["@import '../../../../components/atoms/glass/mixins';\n\n.hoverInfo {\n  @include glassBackground;\n\n  position: absolute;\n  z-index: 0;\n  max-width: 480px;\n  border-radius: 5px;\n}\n","// https://www.sketch.com/s/b13841a7-cfd2-47e8-a114-efb8e29285af/a/JAOznjr\n\n@mixin glass {\n  backdrop-filter: blur(15px);\n  box-shadow: 0px 0px 10px rgba(75, 0, 130, 0.09);\n  background-color: rgba(75, 0, 130, 0.09);\n}\n\n@mixin glassBackground {\n  position: relative;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: -1;\n\n    @include glass;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hoverInfo": "GraphHoverInfo-module__hoverInfo___UJD9f"
};
export default ___CSS_LOADER_EXPORT___;
