// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RuneCode-module__errorHighlight___dbawK{background:#735603;border-bottom:#ff0 2px dotted;padding:0px 3px}", "",{"version":3,"sources":["webpack://./src/pages/robot/Soul/RuneEditor/RuneCode/RuneCode.module.scss"],"names":[],"mappings":"AAAA,yCACE,kBAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".errorHighlight {\n  background: #735603;\n  border-bottom: yellow 2px dotted;\n  padding: 0px 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorHighlight": "RuneCode-module__errorHighlight___dbawK"
};
export default ___CSS_LOADER_EXPORT___;
