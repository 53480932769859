// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feeds-module__containerLogRows___VUmH4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:35px 0px;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;padding-bottom:50px}.feeds-module__containerLogRows___VUmH4 p{word-break:break-word}", "",{"version":3,"sources":["webpack://./src/pages/robot/_refactor/account/tabs/feeds/feeds.module.scss"],"names":[],"mappings":"AAAA,wCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,YAAA,CACA,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,mBAAA,CAEA,0CACE,qBAAA","sourcesContent":[".containerLogRows {\n  display: flex;\n  flex-direction: column;\n  gap: 35px 0px;\n  align-items: flex-end;\n  padding-bottom: 50px;\n\n  p {\n    word-break: break-word;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerLogRows": "feeds-module__containerLogRows___VUmH4"
};
export default ___CSS_LOADER_EXPORT___;
