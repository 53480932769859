// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Statistics-module__container___Ly05F{margin-top:10px;margin-bottom:50px;display:grid;grid-template-columns:repeat(auto-fit, minmax(250px, 1fr));grid-gap:20px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/containers/mint/Statistics/Statistics.module.scss"],"names":[],"mappings":"AACA,sCACE,eAAA,CACA,kBAAA,CACA,YAAA,CACA,0DAAA,CACA,aAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":["\n.container {\n  margin-top: 10px;\n  margin-bottom: 50px;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));;\n  grid-gap: 20px;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Statistics-module__container___Ly05F"
};
export default ___CSS_LOADER_EXPORT___;
